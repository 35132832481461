import React, { Component, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Button from "@material-ui/core/Button";
import { Alert } from "reactstrap";

const DiaLogBox = (props) => {
  const { open, title, description, deleteAction, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`${title} delete confirmation`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Do you want to delete this ${title} ? `}
          {/* // Choose "Disagree" to prevent the action, Choose "Agree" to take action. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={deleteAction} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const DiaLogBox1 = (props) => {
  const { open, title, description, deleteAction, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={deleteAction} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const AlertBOX = (props) => {
  const { color, open, message } = props;
  const [state, closeThis] = useState(open !== undefined ? open : false);

  return (
    <Alert
      className="shadow-lg"
      color={color}
      isOpen={state}
      toggle={() => closeThis(false)}
    >
      {message}
    </Alert>
  );
};

const NavLinkbutton = (props) => {
  const { title, link, iclass, color } = props;
  return (
    <NavLink className="prepend-icon float-right" to={link}>
      <span className="nav-text">
        <Button
          variant="raised"
          color={color}
          className=" jr-btn jr-btn-label CBmargin left"
        >
          <i className={`zmdi ${iclass} zmdi-hc-fw `} />
          <span className="nowrap">{title}</span>
        </Button>
      </span>
    </NavLink>
  );
};
NavLinkbutton.defaultProps = {
  color: "primary",
  iclass: "zmdi zmdi-account-add",
};

const Accodian = (props) => {
  const { panelId, expanded, handleChange, label, icon, form, bgColor } = props;

  return (
    <ExpansionPanel
      square
      expanded={expanded === panelId}
      onChange={handleChange(panelId)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={"classes.heading"}>
          <Chip
            size="small"
            style={{ backgroundColor: bgColor, color: "#fff" }}
            icon={icon}
            label={label}
          />
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography style={{ width: "100%" }}>{form}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
const DialogBox = React.memo(DiaLogBox);
const DialogBox1 = React.memo(DiaLogBox1);
const NavLinkButton = React.memo(NavLinkbutton);
const AlertBox = AlertBOX;

export { DialogBox, DialogBox1, AlertBox, NavLinkButton, Accodian };
