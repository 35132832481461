import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
export default () => {
  const PERMISSIONS =
    localStorage.getItem("permission") !== null
      ? JSON.parse(localStorage.getItem("permission"))
      : [];
  console.log(PERMISSIONS, localStorage.getItem("permission"), 65655);
  return (
    <ul className="nav-menu">
      <li>
        <NavLink to="/app/dashboard/index">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.dashboard" />
          </span>
        </NavLink>
      </li>
      {/* {PERMISSIONS.includes("dispatchers") && (
        <li className="menu no-arrow">
          <NavLink to="/app/dispatchers">
            <i className="zmdi zmdi-account-box-o zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.dispatcher" />{" "}
            </span>
          </NavLink>
        </li>
      )} */}

      {/* {PERMISSIONS.includes("franchies") && (
        <li className="menu no-arrow">
          <NavLink to="/app/dispatchers">
            <i className="zmdi zmdi-account-box-o zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.franchies" />{" "}
            </span>
          </NavLink>
        </li>
      )}

      {PERMISSIONS.includes("support") && (
        <li className="menu no-arrow">
          <NavLink to="/app/dispatchers">
            <i className="zmdi zmdi-account-box-o zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.support" />{" "}
            </span>
          </NavLink>
        </li>
      )} */}
      {/* {PERMISSIONS.includes("hotels") && (
        <li className="menu no-arrow">
          <NavLink to="/app/hotels">
            <i className="zmdi zmdi-hotel zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.hotel" />{" "}
            </span>
          </NavLink>
        </li>
      )} */}
      {PERMISSIONS.includes("customers") && (
        <li className="menu no-arrow">
          <NavLink to="/app/customers">
            <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
            <span className="nav-text">Customers </span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("drivers") && (
        <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
          <span className="nav-text">Drivers</span>
        </Button>
        <ul className="sub-menu">
       
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/drivers/list">
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">All</span>
            </NavLink>
          </li>
           
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/drivers/approved"
            >
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">Approved </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/drivers/new">
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">New </span>
            </NavLink>
          </li>
        </ul>
      </li>
      )}
      {PERMISSIONS.includes("brands") && (
       <li className="menu no-arrow">
        <NavLink to="/app/brands">
          <i
            className="zmdi zmdi-pin-assistant"
            style={{ marginLeft: "5px" }}
          />
          <span className="nav-text">Brand </span>
        </NavLink>
      </li>
      )}
      {PERMISSIONS.includes("Reports") && (
        <li className="menu no-arrow">
        <NavLink to="/app/report/list">
        <i class="zmdi zmdi-comment-alt-text" />
          <span className="nav-text">Report</span>
        </NavLink>
      </li>
      )}
      {PERMISSIONS.includes("categories") && (
   <li className="menu no-arrow">
   <NavLink to="/app/categories/list">
     <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
     <span className="nav-text">Categories</span>
   </NavLink>
 </li>
      )}
       {PERMISSIONS.includes("subcategories") && (
       <li className="menu no-arrow">
        <NavLink to="/app/subcategories/list">
          <i
            className="zmdi zmdi-dot-circle-alt"
            style={{ marginLeft: "4px" }}
          />
          <span className="nav-text">Sub Categories</span>
        </NavLink>
      </li>
      )}
        {PERMISSIONS.includes("varients") && (
           <li className="menu no-arrow">
           <NavLink to="/app/varients/list">
             <i className="zmdi zmdi-circle-o" style={{ marginLeft: "4px" }} />
             <span className="nav-text">Variants</span>
           </NavLink>
         </li>
        )}
        {PERMISSIONS.includes("products") && (
           <li className="menu collapse-box">
           <Button href="javascript:void(0)" className="cbutton">
             <i
               className="zmdi zmdi-shopping-cart-plus"
               style={{ marginLeft: "4px" }}
             />
             <span className="nav-text">Master Products</span>
           </Button>
           <ul className="sub-menu">
             <li>
               <NavLink className="prepend-icon cbutton" to="/app/products/list">
                 <i className="zmdi zmdi-shopping-cart-plus" />
                 <span className="nav-text">All</span>
               </NavLink>
             </li>
   
             <li>
               <NavLink className="prepend-icon cbutton" to="/app/products/import">
                 <i class="zmdi zmdi-shopping-cart-plus"></i>
   
                 <span className="nav-text">CSV import</span>
               </NavLink>
             </li>
           </ul>
         </li>
        )}
        {PERMISSIONS.includes("vendor") && (
           <li className="menu collapse-box">
           <Button href="javascript:void(0)" className="cbutton">
             <i className="zmdi zmdi-store zmdi-hc-fw" />
             <span className="nav-text">Stores</span>
           </Button>
           <ul className="sub-menu">
             {/* <li className="menu no-arrow"> */}
             <li>
               <NavLink className="prepend-icon cbutton" to="/app/vendor/list">
                 <i className="zmdi zmdi-store" />
                 <span className="nav-text">All </span>
               </NavLink>
             </li>
             {/* <ul className="sub-menu">
                 <li className="menu no-arrow">
                   <NavLink className="prepend-icon cbutton" to="/app/vendor/list">
                     <i className="zmdi zmdi-store" />
                     <span className="nav-text">All </span>
                   </NavLink>
                 </li> */}
             <li>
               <NavLink className="prepend-icon cbutton" to="/app/vendor/approved">
                 <i className="zmdi zmdi-store" />
                 <span className="nav-text">Approved </span>
               </NavLink>
             </li>
             <li>
               <NavLink className="prepend-icon cbutton" to="/app/vendor/new">
                 <i className="zmdi zmdi-store" />
                 <span className="nav-text">New </span>
               </NavLink>
             </li>
           </ul>
         </li>
        )}
        {PERMISSIONS.includes("heatmap") && (    <li className="menu no-arrow">
        <NavLink to="/app/heatmap/locations">
          <i className="zmdi zmdi-pin zmdi-hc-fw" />
          <span className="nav-text">Heat Map</span>
        </NavLink>
      </li>)}
        {PERMISSIONS.includes("subscription") && ( <li className="menu no-arrow">
        <NavLink to="/app/subscription">
          <i className="zmdi zmdi-group-work" />
          <span className="nav-text">Subscription</span>
        </NavLink>
      </li>)}
      
     

    
      {PERMISSIONS.includes("orders") && (
       <li className="menu collapse-box">
       <Button href="javascript:void(0)" className="cbutton">
         <i className="zmdi zmdi-receipt zmdi-hc-fw" />
         <span className="nav-text">Orders</span>
       </Button>
       <ul className="sub-menu">
      
         <li>
           <NavLink className="prepend-icon cbutton" to="/app/orders/list">
             <i className="zmdi zmdi-receipt" />
             <span className="nav-text">All</span>
           </NavLink>
         </li>
      
         <li>
           <NavLink className="prepend-icon cbutton" to="/app/orders/completed">
             <i className="zmdi zmdi-receipt" />
             <span className="nav-text">Completed</span>
           </NavLink>
         </li>
         <li>
           <NavLink className="prepend-icon cbutton" to="/app/orders/new">
             <i className="zmdi zmdi-receipt" />
             <span className="nav-text">Declined/Cancelled</span>
           </NavLink>
         </li>
       </ul>
     </li>
      )}
      {PERMISSIONS.includes("promo-codes") && (
       <li className="menu no-arrow">
       <NavLink to="/app/promo-codes">
         <i className="zmdi zmdi-label zmdi-hc-fw" />
         <span className="nav-text">Promo Codes</span>
       </NavLink>
     </li>
      )}
      {PERMISSIONS.includes("earnings") && (
          <li className="menu no-arrow">
          <NavLink to="/app/earnings">
            <i className="zmdi zmdi-money-box" style={{ marginLeft: "5px" }} />
            <span className="nav-text">Earning </span>
          </NavLink>
        </li>
      )}
     

      {PERMISSIONS.includes("push_notification") && (
        <li>
          <NavLink to="/app/notification">
            <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
            <span className="nav-text">Push Notification</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("content_pages") && (
        <li>
          <NavLink to="/app/contentPages">
            <i className="zmdi zmdi-file-text zmdi-hc-fw" />
            <span className="nav-text">Content Pages</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("faq") && (
        <li>
          <NavLink to="/app/faq">
            <i className="zmdi zmdi-comments zmdi-hc-fw" />
            <span className="nav-text">FAQ</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("admin_settings") && (
        <li>
          <NavLink to="/app/users/list">
            <i className="zmdi zmdi-comments zmdi-hc-fw" />
            <span className="nav-text">Admin Setting</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("basic_settings") && (
        <li>
          <NavLink className="prepend-icon cbutton" to="/app/settings/basic">
            <i class="zmdi zmdi-album"></i>

            <span className="nav-text">Basic Settings</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("installation_setting") && (
        <li>
          <NavLink
            className="prepend-icon cbutton"
            to="/app/settings/installation"
          >
            <i class="zmdi zmdi-remote-control"></i>

            <span className="nav-text">Installation Settings</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("mail_settings") && (
        <li>
          <NavLink className="prepend-icon cbutton" to="/app/settings/mail">
            <i class="zmdi zmdi-email"></i>

            <span className="nav-text">Mail Templates</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS.includes("sms_settings") && (
        <li>
          <NavLink className="prepend-icon cbutton" to="/app/settings/sms">
            <i class="zmdi zmdi-comment-text"></i>

            <span className="nav-text">SMS Templates</span>
          </NavLink>
        </li>
      )}
    </ul>
  );
};
