import React, { useState, useEffect, Fragment } from "react";
import {
  withRouter,
  toast,
  Avatar,
  Axios,
  DialogBox,
  TableBody,
  TableRow,
  TableCell,
  DataTable,
  Action,
  DataTableHead,
  DataTableBody,
  DataTablePagination,
  CircularProgress,
  moment,
  Status,
  ApiUrl,
} from "../../../../components";
const { API_GET_ADMIN_ORDER } = ApiUrl;

const columnData = [
  { id: "storeId", align: false, disablePadding: true, label: "#", order: 1 },
  {
    id: "_id",
    align: false,
    disablePadding: false,
    label: "Order No.",
    order: 1,
  },
  {
    id: "createdAt",
    align: true,
    disablePadding: false,
    label: "Order Date",
    type: "date",
    order: 1,
  },
  {
    id: "customerName",
    align: false,
    disablePadding: true,
    label: "Customer",
    order: 1,
  },
  {
    id: "totalAmount",
    align: true,
    disablePadding: false,
    label: "Amount($)",
    order: 1,
  },
  {
    id: "customerPhoneNumber",
    align: false,
    disablePadding: false,
    label: "Phone Number",
    order: 1,
  },
  // {
  //   id: "paymentMethod",
  //   align: false,
  //   disablePadding: false,
  //   label: "Payment Method",
  //   order: 1,
  // },
  {
    id: "paymentStatus",
    align: false,
    disablePadding: false,
    label: "Status",
    order: 1,
  },
  // {
  //   id: "orderStatus",
  //   align: false,
  //   disablePadding: false,
  //   label: "Order Status",
  //   order: 1,
  // },
  { id: "action", align: true, disablePadding: false, label: "Action" },
  // {
  //   id: "customerEmail",
  //   align: false,
  //   disablePadding: false,
  //   label: "Customer Email",
  //   order: 1,
  // },
  // {
  //   id: "orderCompletedAt",
  //   align: true,
  //   disablePadding: false,
  //   label: "Completed At",
  //   type: "date",
  //   order: 1,
  // },
];


const ListOrders = (props) => {
  const { categoryId } = props.match.params;
  const [columns, setColumns] = useState(columnData);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  // const [order, setOrder] = useState(-1);
  // const [orderBy, setOrderBy] = useState("createdAt");
  // const [fieldName, setFieldName] = useState("itemName");
  // const [fieldValue, setFieldValue] = useState("");
  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    getTableRecords();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleRequestSort = (data) => {
  //   if (data.key !== "action") {
  //     const newColumns = columns.map((key) => {
  //       if (key.id !== data.key) {
  //         return { ...key, order: 1 };
  //       } else {
  //         return { ...key, order: data.order };
  //       }
  //     });

  //     setColumns(newColumns);
  //     setOrderBy(data.key);
  //     setOrder(data.order);
  //   }
  // };

  const getTableRecords = async () => {
    setLoading(true);
    // const body = {
    //   categoryId,
    //   orderBy,
    //   order,
    //   page: page + 1,
    //   limit: rowsPerPage,
    //   categoryId: localStorage.getItem("_id"),
    //   ...(fieldName &&
    //   fieldValue &&
    //   !["subCategories", "categories"].includes(fieldName)
    //     ? { fieldName, fieldValue }
    //     : {}),
    // };
    
    try {
      Axios.post(API_GET_ADMIN_ORDER, { page: page + 1, limit: rowsPerPage}).then((response) => {
        console.log(response, 54321);
        // {
        //   "data": {
        //     "orders": [
        //       {
        //         "items": [
        //           {
        //             "itemId": "64a0db02b95f23b33b8ae21a",
        //             "itemName": "Twist Up 12 Oz",
        //             "qty": 1,
        //             "size": [
        //               {
        //                 "sizeUnit": null,
        //                 "size": null,
        //                 "price": 2
        //               }
        //             ],
        //             "categoryId": "61497de14d2c9997b4d18b4a"
        //           }
        //         ],
        //         "paymentMethod": "stripe",
        //         "transactionId": "plink_1PwK9JF6mhTTq7QseatseUuY",
        //         "paymentStatus": "pending",
        //         "orderStatus": "pending",
        //         "_id": "66dc10d3b271943f78cdd269",
        //         "customerId": "61a73b0081addeb31fa5f1e2",
        //         "customerName": "kjkh",
        //         "customerEmail": "nati@gm.com",
        //         "customerPhoneNumber": "23456564566",
        //         "storeId": "61a73b0081addeb31fa5f1e2",
        //         "totalAmount": 7.19,
        //         "tax": 0.2,
        //         "isTaxFree": "false",
        //         "totalFee": 4.99,
        //         "updatedAt": "2024-09-07T08:37:39.133Z",
        //         "createdAt": "2024-09-07T08:37:39.143Z"
        //       },
        //       {
        //         "items": [
        //           {
        //             "itemId": "64a0da68b95f23b33b8a7764",
        //             "itemName": "Bulleit Bourbon Frontier Whiskey - 750 ml Bottle",
        //             "qty": 1,
        //             "size": [
        //               {
        //                 "sizeUnit": "ml",
        //                 "size": "750",
        //                 "price": 2
        //               }
        //             ],
        //             "categoryId": "61497de14d2c9997b4d18b3d"
        //           }
        //         ],
        //         "paymentMethod": "stripe",
        //         "transactionId": "plink_1PwKK5F6mhTTq7QsvRMFyJVZ",
        //         "paymentStatus": "pending",
        //         "orderStatus": "pending",
        //         "_id": "66dc136213c4bd22fc1bbbe9",
        //         "customerId": "61a73b0081addeb31fa5f1e2",
        //         "customerName": "kjkh",
        //         "customerEmail": "natimok55@gmail.com",
        //         "customerPhoneNumber": "23456564566",
        //         "storeId": "61a73b0081addeb31fa5f1e2",
        //         "totalAmount": 10.23,
        //         "tax": 3.24,
        //         "isTaxFree": "false",
        //         "totalFee": 4.99,
        //         "updatedAt": "2024-09-07T08:48:34.532Z",
        //         "createdAt": "2024-09-07T08:48:34.543Z"
        //       },
        //       {
        //         "items": [
        //           {
        //             "itemId": "64a0da68b95f23b33b8a7764",
        //             "itemName": "Bulleit Bourbon Frontier Whiskey - 750 ml Bottle",
        //             "qty": 1,
        //             "size": [
        //               {
        //                 "sizeUnit": "ml",
        //                 "size": "750",
        //                 "price": 2
        //               }
        //             ],
        //             "categoryId": "61497de14d2c9997b4d18b3d"
        //           }
        //         ],
        //         "paymentMethod": "stripe",
        //         "transactionId": "plink_1PwKPiF6mhTTq7QseomTcZOb",
        //         "paymentStatus": "pending",
        //         "orderStatus": "pending",
        //         "_id": "66dc14ccab76434a68a002fe",
        //         "customerId": "61a73b0081addeb31fa5f1e2",
        //         "customerName": "kjkh",
        //         "customerEmail": "natimok55@gmail.com",
        //         "customerPhoneNumber": "23456564566",
        //         "storeId": "61a73b0081addeb31fa5f1e2",
        //         "totalAmount": 10.23,
        //         "tax": 3.24,
        //         "isTaxFree": "false",
        //         "totalFee": 4.99,
        //         "updatedAt": "2024-09-07T08:54:36.364Z",
        //         "createdAt": "2024-09-07T08:54:36.376Z"
        //       },
        //       {
        //         "items": [
        //           {
        //             "itemId": "64a0da68b95f23b33b8a7764",
        //             "itemName": "Bulleit Bourbon Frontier Whiskey - 750 ml Bottle",
        //             "qty": 1,
        //             "size": [
        //               {
        //                 "sizeUnit": "ml",
        //                 "size": "750",
        //                 "price": 2
        //               }
        //             ],
        //             "categoryId": "61497de14d2c9997b4d18b3d"
        //           }
        //         ],
        //         "paymentMethod": "stripe",
        //         "transactionId": "plink_1PwKRSF6mhTTq7QsSauOuJgL",
        //         "paymentStatus": "success",
        //         "orderStatus": "completed",
        //         "_id": "66dc1538e9100c47d4f83617",
        //         "customerId": "61a73b0081addeb31fa5f1e2",
        //         "customerName": "kjkh",
        //         "customerEmail": "natimok55@gmail.com",
        //         "customerPhoneNumber": "23456564566",
        //         "storeId": "61a73b0081addeb31fa5f1e2",
        //         "totalAmount": 10.23,
        //         "tax": 3.24,
        //         "isTaxFree": "false",
        //         "totalFee": 4.99,
        //         "updatedAt": "2024-09-07T08:56:24.601Z",
        //         "createdAt": "2024-09-07T08:56:24.607Z"
        //       }
        //     ],
        //     "pagination": {
        //       "currentPage": 1,
        //       "totalPages": 1,
        //       "totalOrders": 4,
        //       "pointer": 10
        //     }
        //   },
        // }
        setData(response.data.orders);
        setTotalCount(response.data.pagination.totalOrders);
        setLoading(false);
      });
      // const restaurantResponse = await Axios.post(API_GET_RESTAURANTS_URL);
      // console.log(restaurantResponse, 1234321);

      // const productResponse = await Axios.post(API_GET_PRODUCT_LIST, body);
      // console.log(productResponse, 78787878);

      // const responseData = productResponse.data;
    //   if (["subCategories", "categories"].includes(fieldName)) {
    //     if (responseData.length > 0) {
    //       const key = {
    //         categories: "catName",
    //         subCategories: "subcatName",
    //       };
    //       const filtered = responseData.filter((p) => {
    //         if (
    //           p[fieldName].length > 0 &&
    //           p[fieldName][0][key[fieldName]]
    //             .toLowerCase()
    //             .includes(fieldValue.toLowerCase())
    //         ) {
    //           return { ...p };
    //         }
    //         return null;
    //       });
    //       setTotalCount(filtered.length);
    //       setData(filtered);
    //     }
    //   } else {
    //     setTotalCount(responseData.totalcount);
    //     setData(responseData.data);
    //   }
    } catch (err) {
      toast.error("No data Found.");
    } finally {
      setLoading(false);
    }
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   if (name === "fieldName") {
  //     setFieldName(value);
  //   } else if (name === "fieldValue") {
  //     setFieldValue(value);
  //   }
  // };

  // const handleClickOpen = (id) => {
  //   setOpen(true);
  //   setSelectedId(id);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const filterData = (e) => {
  //   e.preventDefault();
  //   setPage(0);
  //   setRowsPerPage(["categories"].includes(fieldName) ? 1000 : 10);
  // };

  // const removeFilterButton = () => {
  //   setTotalCount(0);
  //   setRowsPerPage(5);
  //   setPage(0);
  //   setOrder(1);
  //   setFieldName("itemName");
  //   setFieldValue("");
  //   setOrderBy("createdAt");
  // };

  return (
    <Fragment>
      {/* <DialogBox title={"Product"} open={open} onClose={handleClose} /> */}
      {/* <ProductFilters
        fieldName={fieldName}
        handleChange={handleChange}
        fieldValue={fieldValue}
        filterData={filterData}
        removeFilter={removeFilterButton}
      /> */}
      <div className="flex-auto">
        <div className="table-responsive-material">
          <DataTable className="">
            <DataTableHead
              columns={columns}
              // orderBy={orderBy}
              // sort={handleRequestSort}
            />
            <DataTableBody
              loading={loading}
              column={columns}
              data={data}
              page={page}
              rowsPerPage={rowsPerPage}
              actions={[
                { label: "View", icon: "", link: `/app/products/view` },
                // { label: "Edit", icon: "", link: `/app/products/edit` },
              ]}
            />
            {data.length > 0 && (
              <DataTablePagination
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={[]}
              />
            )}
          </DataTable>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(ListOrders);
