import React, { Fragment } from 'react'
import ListOrders from './Components/ListOrders'
import PageWrapper from "../common/PageWrapper";


function AdminOrders() {
  return (
    <PageWrapper
        title={"Admin Order List"}
        icon="zmdi zmdi-shopping-cart-plus"
      >
        <Fragment>
        <ListOrders />
        </Fragment>
    </PageWrapper>
  )
}

export default AdminOrders