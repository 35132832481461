const globalConstants = {	
  APIS: {
    LOCALHOST: {
      URL: "http://127.0.0.1:5004",
    },
    TEST: {
      //URL: "http://127.0.0.1:5004",
      URL: "https://api-dev.alcooly.com",
    },
    LIVE: {
      //URL: "http://127.0.0.1:5004",
      URL: "https://api.alcooly.com",
    },
    STAGING: {
      //URL: "http://127.0.0.1:5004",
      URL: "https://api-dev.alcooly.com",
    },
  },
};

module.exports = globalConstants;
