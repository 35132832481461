import React from 'react';
import ReactDOM from 'react-dom';
import "./App.css"
const rootEl = document.getElementById('app-site');
const MainApp = require('./MainApp').default;

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}
if (process.env.NODE_ENV === 'development') {
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}

ReactDOM.render(
  <MainApp/>,
  rootEl
);
