import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";

import Dashboard from "./routes/dashboard";
import Customers from "./routes/customers";
import Drivers from "./routes/drivers";
import CarTypes from "./routes/car-types";
import Trips from "./routes/trips";
import OrderProducts from "./routes/orderproducts";
import AdminOrders from "./routes/adminOrders"
import PromoCodes from "./routes/promo-codes";
import Earning from "./routes/earning";
import Report from "./routes/report";

import AdminUser from "./routes/users";

import PushNotification from "./routes/notification";
import Faq from './routes/faq';
import ContentPage from './routes/contentPages';
import Settings from "./routes/settings";
import Pricing from "./routes/pricing";
import Cuisines from './routes/cuisines';
import Promotions from './routes/promotions';
import Restaurants from './routes/vendor';
import Subscription from './routes/subscription';

import StoreTypes from "./routes/store-types";
import Categories from "./routes/categories";
import SubCategories from "./routes/sub-categories";
import Varient from "./routes/varient";
import Products from "./routes/products";
import Brands from "./routes/brand";
import Heatmap from "./routes/geo";


import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from "constants/ActionTypes";

import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route path={`${match.url}/drivers`} component={Drivers} />
                <Route path={`${match.url}/customers`} component={Customers} />
                <Route path={`${match.url}/orders`} component={Trips} />
                <Route path={`${match.url}/orderproducts`} component={OrderProducts} />
                <Route path={`${match.url}/adminorders`} component={AdminOrders} />
                <Route path={`${match.url}/car-types`} component={CarTypes}/>
                <Route path={`${match.url}/promo-codes`} component={PromoCodes} />
                <Route path={`${match.url}/notification`} component={PushNotification} />
                <Route path={`${match.url}/contentPages`} component={ContentPage}/>
                <Route path={`${match.url}/faq`} component={Faq}/>
                <Route path={`${match.url}/store-types`} component={StoreTypes} />
                <Route path={`${match.url}/categories`} component={Categories} />
                <Route path={`${match.url}/subcategories`} component={SubCategories} />
                <Route path={`${match.url}/varients`} component={Varient} />
                <Route path={`${match.url}/report`} component={Report} />
                <Route path={`${match.url}/products`} component={Products}/>
                <Route path={`${match.url}/brands`} component={Brands}/>
                <Route path={`${match.url}/heatmap`} component={Heatmap}/>
                <Route path={`${match.url}/earnings`} component={Earning}/>
                <Route path={`${match.url}/subscription`} component={Subscription}/>
                
                
                <Route path={`${match.url}/pricing`} component={Pricing}/>
                <Route path={`${match.url}/cuisines`} component={Cuisines}/>
                <Route path={`${match.url}/promotions`} component={Promotions}/>
                <Route path={`${match.url}/vendor`} component={Restaurants}/>
                
              <Route path={`${match.url}/users`} component={AdminUser} />
              <Route path={`${match.url}/settings`} component={Settings} />
             
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
            <ToastContainer autoClose={2000} />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
