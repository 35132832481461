import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
export default () => {
  return (
    <ul className="nav-menu">
      <li className="menu no-arrow">
        <NavLink to="/app/dashboard/index">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Dashboard</span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/customers">
          <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
          <span className="nav-text">Customers </span>
        </NavLink>
      </li>
      {/* <li  >
            <NavLink to="/app/drivers">
              <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
              <span className="nav-text">Drivers </span>
            </NavLink>
          </li> */}
      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
          <span className="nav-text">Drivers</span>
        </Button>
        <ul className="sub-menu">
          {/* <li className="menu no-arrow"> */}
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/drivers/list">
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">All</span>
            </NavLink>
          </li>
          {/* <ul className="sub-menu">
              <li className="menu no-arrow">
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/drivers/list"
                >
                  <i className="zmdi zmdi-local-taxi" />
                  <span className="nav-text">All </span>
                </NavLink>
              </li> */}
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/drivers/approved"
            >
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">Approved </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/drivers/new">
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">New </span>
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/brands">
          <i
            className="zmdi zmdi-pin-assistant"
            style={{ marginLeft: "5px" }}
          />
          <span className="nav-text">Brand </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/report/list">
          <i class="zmdi zmdi-comment-alt-text" />
          <span className="nav-text">Report</span>
        </NavLink>
      </li>
      {/* <li className="menu no-arrow">
            <NavLink to="/app/car-types">
              <i className="zmdi zmdi-copy zmdi-hc-fw" />
              <span className="nav-text">Vehicle Types </span>
            </NavLink>
          </li> */}

      {/* <li  >
            <NavLink to="/app/trips">
              <i className="zmdi zmdi-receipt zmdi-hc-fw" />
              <span className="nav-text">Orders </span>
            </NavLink>
          </li> */}
      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-receipt zmdi-hc-fw" />
          <span className="nav-text">Orders</span>
        </Button>
        <ul className="sub-menu">
          {/* <li className="menu no-arrow"> */}
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/orders/list">
              <i className="zmdi zmdi-receipt" />
              <span className="nav-text">All</span>
            </NavLink>
          </li>
          {/* <ul className="sub-menu">
              <li className="menu no-arrow">
                <NavLink className="prepend-icon cbutton" to="/app/trips/list">
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">All</span>
                </NavLink>
              </li> */}
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/orders/completed"
            >
              <i className="zmdi zmdi-receipt" />
              <span className="nav-text">Completed</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/orders/new">
              <i className="zmdi zmdi-receipt" />
              <span className="nav-text">Declined/Cancelled</span>
            </NavLink>
          </li>
        </ul>
      </li>

      {/*  <li className="menu no-arrow">
            <NavLink to="/app/store-types">
              <i className="zmdi zmdi-star-circle zmdi-hc-fw" />
              <span className="nav-text">Store Types</span>
            </NavLink>
          </li> */}

      <li className="menu no-arrow">
        <NavLink to="/app/orderproducts/create">
          <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
          <span className="nav-text">Order Products</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/adminorders">
          <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
          <span className="nav-text">Admin Orders</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/categories/list">
          <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
          <span className="nav-text">Categories</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/subcategories/list">
          <i
            className="zmdi zmdi-dot-circle-alt"
            style={{ marginLeft: "4px" }}
          />
          <span className="nav-text">Sub Categories</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/varients/list">
          <i className="zmdi zmdi-circle-o" style={{ marginLeft: "4px" }} />
          <span className="nav-text">Variants</span>
        </NavLink>
      </li>
      {/* <li className="menu no-arrow">
            <NavLink to="/app/products/list">
              <i class="zmdi zmdi-shopping-cart-plus"></i>
              <span className="nav-text">Products</span>
            </NavLink>
          </li> */}

      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i
            className="zmdi zmdi-shopping-cart-plus"
            style={{ marginLeft: "4px" }}
          />
          <span className="nav-text">Master Products</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/products/list">
              <i className="zmdi zmdi-shopping-cart-plus" />
              <span className="nav-text">All</span>
            </NavLink>
          </li>

          <li>
            <NavLink className="prepend-icon cbutton" to="/app/products/import">
              <i class="zmdi zmdi-shopping-cart-plus"></i>

              <span className="nav-text">CSV import</span>
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-store zmdi-hc-fw" />
          <span className="nav-text">Stores</span>
        </Button>
        <ul className="sub-menu">
          {/* <li className="menu no-arrow"> */}
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/vendor/list">
              <i className="zmdi zmdi-store" />
              <span className="nav-text">All </span>
            </NavLink>
          </li>
          {/* <ul className="sub-menu">
              <li className="menu no-arrow">
                <NavLink className="prepend-icon cbutton" to="/app/vendor/list">
                  <i className="zmdi zmdi-store" />
                  <span className="nav-text">All </span>
                </NavLink>
              </li> */}
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/vendor/approved">
              <i className="zmdi zmdi-store" />
              <span className="nav-text">Approved </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/vendor/new">
              <i className="zmdi zmdi-store" />
              <span className="nav-text">New </span>
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/heatmap/locations">
          <i className="zmdi zmdi-pin zmdi-hc-fw" />
          <span className="nav-text">Heat Map</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/subscription">
          <i className="zmdi zmdi-group-work" />
          <span className="nav-text">Subscription</span>
        </NavLink>
      </li>
      {/* <li className="menu no-arrow">
            <NavLink to="/app/pricing">
              <i className="zmdi zmdi-money-box zmdi-hc-fw" />
              <span className="nav-text">Pricing </span>
            </NavLink>
          </li> */}
      {/* <li className="menu no-arrow">
            <NavLink to="/app/cuisines">
              <i className="zmdi zmdi-star zmdi-hc-fw" />
              <span className="nav-text">Cuisines</span>
            </NavLink>
          </li> */}
      {/* <li  >
            <NavLink to="/app/promotions">
              <i className="zmdi zmdi-labels zmdi-hc-fw" />
              <span className="nav-text">Promotions</span>
            </NavLink>
          </li> */}
      <li className="menu no-arrow">
        <NavLink to="/app/promo-codes">
          <i className="zmdi zmdi-label zmdi-hc-fw" />
          <span className="nav-text">Promo Codes</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/earnings">
          <i className="zmdi zmdi-money-box" style={{ marginLeft: "5px" }} />
          <span className="nav-text">Earning </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/contentPages">
          <i className="zmdi zmdi-file-text zmdi-hc-fw" />
          <span className="nav-text">Content Pages</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/faq">
          <i className="zmdi zmdi-comments zmdi-hc-fw" />
          <span className="nav-text">FAQ</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/app/notification">
          <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
          <span className="nav-text">Push Notification</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/users/list">
          <i className="zmdi zmdi-comments zmdi-hc-fw" />
          <span className="nav-text">Admin Setting</span>
        </NavLink>
      </li>
      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-settings zmdi-hc-fw" />
          <span className="nav-text">Settings</span>
        </Button>
        <ul className="sub-menu">
          {/* <li className="menu no-arrow"> */}
          {/* <li>
                <NavLink className="prepend-icon cbutton" to="/app/users/list">
                  <i className="zmdi zmdi-account" />
                  <span className="nav-text">Admin Settings</span>
                </NavLink>
              </li> */}
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/settings/basic">
              <i class="zmdi zmdi-album"></i>
              <span className="nav-text">Basic Settings</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/settings/installation"
            >
              <i class="zmdi zmdi-remote-control"></i>

              <span className="nav-text">Installation Settings</span>
            </NavLink>
          </li>

          <li>
            <NavLink className="prepend-icon cbutton" to="/app/settings/mail">
              <i class="zmdi zmdi-email"></i>

              <span className="nav-text">Mail templates</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/settings/sms">
              <i class="zmdi zmdi-comment-text"></i>

              <span className="nav-text">SMS Templates</span>
            </NavLink>
          </li>
        </ul>
      </li>
    </ul>
  );
};
